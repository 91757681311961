import alertify from 'alertifyjs';
import React from 'react';
import apix from './Apix';

export const renderHTML = (rawHTML) =>
   React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

export const renderHTMLOneLine = (rawHTML) =>
   React.createElement('div', {
      dangerouslySetInnerHTML: {
         __html: rawHTML?.replace(/(<([^>]+)>)/gi, ' '),
      },
   });

export const removeHtmlTags = (html) => {
   var div = document.createElement('div');
   div.innerHTML = html;
   return div.textContent || div.innerText || '';
};

export const createPdfBlobUrl = (data) => {
   const blob = new Blob([data], { type: 'application/pdf' });
   return URL.createObjectURL(blob);
};

export const toUrlPath = (name) => {
   if (!name) return '';
   return name.replace(' ', '-').toLowerCase();
};

export const downloadFile = (blob, filename) => {
   var a = document.createElement('a');
   a.href = window.URL.createObjectURL(blob);
   a.download = filename;
   a.click();
};

const decodeMsg = (msg) => {
   if (msg == undefined || typeof msg != 'string' || msg == null || msg == {})
      return '';
   // return msg
   //    ?.replace(/&/g, '&amp;')
   //    ?.replace(/</g, '&lt;')
   //    ?.replace(/>/g, '&gt;');
   msg = removeHtmlTags(msg);
   msg = msg.replace(/\n/g, '<br />');
   return msg;
};

export const notifySaveSuccess = () => {
   alertify.success('Successfully saved');
};

export const notifyInfo = (msg) => {
   alertify.success(msg);
};
export const notifyWarning = (msg) => {
   alertify.warning(msg);
};
export const notifyError = (msg) => {
   alertify.error(msg);
};

export const notifyMsg = (msg) => {
   alertify.message(msg);
};

export const alertError = (msg) => {
   alertify.alert('Error', decodeMsg(msg)).set('label', 'OK');
};

export const alertInfo = (msg) => {
   alertify.alert('Information', decodeMsg(msg)).set('label', 'OK');
};
export const alertWarning = (msg) => {
   alertify.alert('Warning ⚠', decodeMsg(msg)).set('label', 'OK');
};

export const showAlert = (msgType = 'Info', header, messages) => {
   const msg = `${header}: <ul><li>${messages.join('</li><li>')}</li></ul>`;
   alertify.alert(msgType, msg.replace('\r\n', '<br>')).set('label', 'OK');
};

export const inputDialog = (
   titleDialog = 'Create',
   label = 'Enter your value',
   value = '',
   description = '',
   onSubmit,
   onCancel
) => {
   alertify
      .prompt(
         titleDialog,
         ` <p>${description}<br /></p> ${label}`,
         value,
         function (event, val) {
            if (onSubmit) onSubmit(event, val);
         },
         function () {
            if (onCancel) onCancel();
         }
      )
      .set('labels', {
         ok: 'Yes',
         cancel: 'No',
      })
      .set('overflow', false);
};

export const confirmDialog = (
   msg = 'Are you sure?',
   onConfirm,
   onCancel,
   confirmTitle = 'Confirm'
) => {
   alertify
      .confirm(
         confirmTitle,
         msg,
         function () {
            if (onConfirm) onConfirm();
         },
         function () {
            if (onCancel) onCancel();
         }
      )
      .set('labels', { ok: 'Yes', cancel: 'No' });
};

// export const confirmDialogWithFormat = (
//    msg = 'Are you sure?',
//    formatMessage = '',
//    confirmTitle = 'Confirm',
//    onConfirm,
//    onCancel
// ) => {
//    const formattedMsg = Array.isArray(formatMessage)
//       ? msg +
//         formatMessage
//            ?.map(
//               (item) =>
//                  `<ul><li> Id: ${item?.id}, Member code: ${item?.memberCode} </li></ul>`
//            )
//            .join('')
//       : msg;
//    alertify
//       .confirm(
//          confirmTitle,
//          formattedMsg,
//          () => {
//             if (onConfirm) onConfirm();
//          },
//          () => {
//             if (onCancel) onCancel();
//          }
//       )
//       .set('labels', { ok: 'Yes', cancel: 'No' });
// };

export const parseJson = (str) => {
   try {
      return JSON.parse(str);
   } catch (e) {
      return false;
   }
};

export const handleResponse = (apixResponse, success, failed) => {
   if (apixResponse.ok) {
      if (success) {
         if (typeof success === 'string') notifyInfo(success);
         if (typeof success === 'function') success(apixResponse.data);
      }
   } else {
      var msg = apixResponse.data;
      if (apixResponse.code == 403)
         alertError('Access denied. You are not authorized to access.');
      else {
         try {
            const json = parseJson(apixResponse.data);
            if (json && json.message) msg = json.message;
         } catch {}
         // if (!onFailed) alertError((failedMessage ?? '') + ' ' + msg);
         if (failed) {
            if (typeof failed === 'string') alertError(`${failed} ${msg}`);
            if (typeof failed === 'function') failed(msg);
         } else alertError(msg.replace(/(<([^>]+)>)/gi, ' ')); // remove out html tags
      }
   }
};

export const getPdfBlobPreview = async (apiEndpoint, setState, setLoading) => {
   if (setLoading) setLoading(true);

   const res = await apix.getBlob(apiEndpoint);
   if (setLoading) setLoading(false);
   //handleBlobPreview(res, setState);

   handleResponse(
      res,
      () => {
         const url = createPdfBlobUrl(res.data);
         setState(url);
      },
      (err) => {
         alertError(err);
         setState(undefined);
      }
   );
};

export const postPdfBlobPreview = async (
   apiEndpoint,
   params,
   setState,
   setLoading
) => {
   if (setLoading) setLoading(true);

   const res = await apix.postBlob(apiEndpoint, params);
   if (setLoading) setLoading(false);

   handleResponse(
      res,
      () => {
         const url = createPdfBlobUrl(res.data);
         setState(url);
      },
      (err) => {
         alertError(err);
         setState(undefined);
      }
   );
};

// export const handleBlobPreview = (res, setState) => {
//    handleResponse(res, () => {
//       const url = createPdfBlobUrl(res.data);
//       setState(url);
//    }, (err) => {
//       alertError(err)
//       setState(undefined)
//    });
// }

export const genId = (length) => {
   var result = '';
   var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
};

export const formatListMessages = (title, msgs) => {
   let msg = '';
   if (title) msg = title + ': <br>';
   if (msgs) {
      const errKeys = Object.keys(msgs);
      msg += `<ul>`;
      errKeys.forEach((x) => {
         msg += `<li> ${x}: ${msgs[x]} </li>`;
      });
      msg += `</ul>`;
   }
   return msg;
};
